import { CONSTANTS } from '../api/constants';

let initState = {
  current: {
    battery: 0.0,
    voltage: 0.0,
    current: 0.0,
    network: 0,
    flightTime: 0,
    isArmed: false,
    latitude: 1.5603437876997344,
    longitude: 103.63915789947508,
    attitude: 0.0,
    airspeed: 0.0,
    adi: {
      pitch: 0.0,
      roll: 0.0,
      yaw: 0.0
    },
    heading: 0,

    altimeter: 0,
    turnCoordinator: 0,


    isPanic: false,
    latPanic: 0,
    lngPanic: 0,
    updatedAt: Date.now()
  },
  selectedDrone: {
    set: false,
    appSerialId: '',
    name: ''
  },
}

export function dashboard(state = initState, action) {
  // console.log(state)
  switch (action.type) {
    case CONSTANTS.DASHBOARD.VIEW:
      return {
        data: state
      }
    case CONSTANTS.DASHBOARD.REQUEST:
      return {
        request: true,
        data: state.id
      };
    case CONSTANTS.DASHBOARD.CURRENT_DATA:
      return {
        ...state,
        ...action.data,
        error: null
      };
    case CONSTANTS.DASHBOARD.SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null
      };
    case CONSTANTS.DASHBOARD.FAILURE:
      return {
        message: action.message,
        data: action.result
      }

    case CONSTANTS.DASHBOARD.SET_DRONE_SELECTED:
      return {
        ...state,
        selectedDrone: { ...action.data, set: true }
      }

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}
