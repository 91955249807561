import { CONSTANTS } from '../api/constants';
let initState = {
  vls: {
    username: '',
    password: '',
    secret: ''
  },
  streamPath: [], // { appSerialId: "<>", path: "<>" }
  drones: [], //
  dronesAppSerialId: {},
  currentVideo: {
    appSerialId: ''
  },
  selectedDrone: {
    set: false,
    appSerialId: '',
    name: ''
  },
  telemetryData: {
    appSerialId: '',
    data: {
      battery: 0.0,
      voltage: 0.0,
      current: 0.0,
      network: 0,
      flightTime: 0,
      isArmed: false,
      latitude: 1.5603437876997344,
      longitude: 103.63915789947508,
      attitude: 0.0,
      airspeed: 0.0,
      adi: {
        pitch: 0.0,
        roll: 0.0,
        yaw: 0.0
      },
      heading: 0,
      altimeter: 0,
      turnCoordinator: 0,

      isPanic: false,
      latPanic: 0,
      lngPanic: 0,
      updatedAt: Date.now()
    }
  },
  defaultTelemetry: {
    data: {
      battery: 0.0,
      voltage: 0.0,
      current: 0.0,
      network: 0,
      flightTime: 0,
      isArmed: false,
      latitude: 1.5603437876997344,
      longitude: 103.63915789947508,
      attitude: 0.0,
      airspeed: 0.0,
      adi: {
        pitch: 0.0,
        roll: 0.0,
        yaw: 0.0
      },
      heading: 0,
      altimeter: 0,
      turnCoordinator: 0,

      isPanic: false,
      latPanic: 0,
      lngPanic: 0,
      updatedAt: Date.now()
    }
  },
  liveData: {}
}

export function drone(state = initState, action) {
  // console.log(state)
  switch (action.type) {
    case CONSTANTS.DRONE.VLS_INFO:
      return {
        ...state,
        vls: action.data
      }
    case CONSTANTS.DRONE.VLS_STREAM:
      return {
        ...state,
        streamPath: action.data
      }
    case CONSTANTS.DRONE.LISTS:
      let dronesAppSerialId = {}
      for (let i = 0; i < action.data.length; i++) {
        dronesAppSerialId[action.data[i].appSerialId] = action.data[i]
      }

      return {
        ...state,
        drones: action.data,
        dronesAppSerialId: dronesAppSerialId
      }
    case CONSTANTS.DRONE.SET_CURRENT_VIDEO_FEED:
      return {
        ...state,
        currentVideo: { appSerialId: action.data }
      }
    case CONSTANTS.DRONE.SET_CURRENT_SELECTED:
      return {
        ...state,
        selectedDrone: { ...action.data, set: true }
      }
    case CONSTANTS.DRONE.TELEMETRY_DATA:
      return {
        ...state,
        telemetryData: action.data
      }
    case CONSTANTS.DRONE.ADD_LIVE_DATA:
      // liveData = {"abcd3":{lat:"",lng:""}}
      let data = state.liveData
      data[action.data.appSerialId] = {
        lat: action.data.lat,
        lng: action.data.lng,
        dates: new Date().valueOf()
      }

      return {
        ...state,
        liveData: data
      }
    case CONSTANTS.DRONE.REMOVE_LIVE_DATA:
      let liveData = state.liveData
      delete liveData[action.data]

      return {
        ...state,
        liveData: liveData
      }

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}
